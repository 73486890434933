<template>
  <LoginPage :title="title">
    <LoginForm
      ref="loginRef"
      :ifErr="ifErr"
      :errMsg="errMsg"
      :loading="loading"
      :usernameRules="usernameRules"
      :passwordRules="passwordRules"
      @login="onLogin"
    />
  </LoginPage>
</template>

<script>
import { LoginPage, LoginForm } from "enn-login-page";
import { DataModel } from "enn-schema-table";
import axios from "@/service/axios";
import { getUrlParam } from "@/utils";
import { setToken, setUser } from "@/utils/info";

export default {
  components: {
    LoginPage,
    LoginForm,
  },
  data() {
    const backName = decodeURIComponent(getUrlParam("backName"));
    if (backName) {
      document.title = backName;
    }
    const title = backName || process.env.VUE_APP_PLATFORM_NAME;
    return {
      title,
      loading: false,
      errMsg: "",
      ifErr: false,
      usernameRules: [
        {
          required: true,
          pattern: "^.{2,16}$",
          message: "请输入2 ~ 16位用户名",
          trigger: ["blur", "change"],
        },
      ],
      passwordRules: [
        {
          required: true,
          pattern: "^.{4,16}$",
          message: "请输入4 ~ 16位密码",
          trigger: ["blur", "change"],
        },
      ],
      // captchaRules: [
      //   {
      //     required: true,
      //     pattern: "^.{4}$",
      //     message: "请输入 4 位验证码",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // captchaId: "",
    };
  },
  created() {
    const {
      params: { message },
    } = this.$route;
    if (message) {
      this.$message.warning(message);
    }
  },
  methods: {
    async onLogin(form) {
      this.loading = true;
      this.ifErr = false;
      const backUrl = getUrlParam("backUrl");
      const params = {
        captchaId: this.captchaId,
        baseUrl: backUrl,
        ...form,
      };
      const loginMd = new DataModel({
        createApi: `/api/v1/user/token`,
      });
      try {
        const user = (await loginMd.create(params)) || {};
        if (user.ErrNo) {
          this.$message.success({
            message: user.ErrMsg,
          });
          return;
        }
        const { token } = user;
        if (backUrl) {
          window.location.href = `${decodeURIComponent(backUrl)}?token=${token}`;
          return;
        }
        this.$message.success({
          message: "登录成功",
        });
        setToken(token);
        setUser(user);
        setTimeout(() => {
          this.$router.push({ name: "overview" });
        }, 100);
        this.loading = false;
      } catch (err) {
        console.log(err)
        if (err) {
          // this.$refs.loginRef.changeCaptcha();
          // this.errMsg = err.message;
          // this.ifErr = true;
          this.loading = false;
        }
      }
    },
    // async getCaptchaId() {
    //   const captchaIdMd = new DataModel({
    //     getApi: `/api/baseUser/v1/captcha/id`,
    //   });
    //   this.captchaId = await captchaIdMd.get();
    //   return this.getCaptchaImage(this.captchaId);
    // },
    // async getCaptchaImage(id) {
    //   const { status, data } =
    //     (await axios.get(`/api/baseUser/v1/captcha/image`, {
    //       params: { id },
    //       responseType: "blob",
    //     })) || {};
    //   if (status === 200 && data) {
    //     return Promise.resolve(window.URL.createObjectURL(data));
    //   }
    // },
  },
};
</script>

<style></style>
